import qs from "qs";
import { Redirect } from "react-router-dom";
import { useParams } from "react-router-dom";

import Error404 from "pages/Error404/Async";

import { selectPodcastByVanityUrl } from "selectors/podcast";
import getPodcastUrl from "utils/entity/getPodcastUrl";

import useQuery from "hooks/useQuery";
import useReduxState from "hooks/useReduxState";

const PodcastVanityUrlRedirect = () => {
  const [query] = useQuery();

  const { vanity_url } = useParams();

  const querystring = query ? `?${qs.stringify(query)}` : "";

  const redirect = useReduxState(
    (state) => {
      if (!state.podcast) {
        // not on a page that requires checking for vanityURL
        return {};
      }
      const podcast = selectPodcastByVanityUrl(state, vanity_url);

      return podcast && getPodcastUrl(podcast) + querystring;
    },
    [vanity_url, querystring]
  );

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return <Error404 />;
};

export default PodcastVanityUrlRedirect;
