import PropTypes from "prop-types";
import { Route, Switch } from "react-router-dom";

import UTMRedirect from "components/Analytics/UTMRedirect";

import Error404 from "../Error404/Async";
import PodcastVanityUrlRedirect from "./PodcastVanityUrlRedirect";

const VanityURL = ({ with404 }) => (
  <Switch>
    <Route
      path="/PatreonPM"
      exact
      children={() => <UTMRedirect campaign="PatreonPM" to="/episodes" />}
    />
    <Route path="/:vanity_url" exact children={<PodcastVanityUrlRedirect />} />
    {with404 && <Route children={<Error404 />} />}
  </Switch>
);

VanityURL.propTypes = {
  with404: PropTypes.bool,
};

VanityURL.defaultProps = {
  with404: false,
};

export default VanityURL;
