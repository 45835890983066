import PropTypes from "prop-types";
import querystring from "qs";
import { Redirect } from "react-router-dom";

const UTMRedirect = ({ to, campaign, medium }) => {
  const qs = querystring.stringify({
    utm_campaign: campaign,
    utm_medium: medium,
    utm_source: "VanityURL",
  });

  return <Redirect to={`${to}?${qs}`} />;
};

UTMRedirect.propTypes = {
  to: PropTypes.string.isRequired,
  campaign: PropTypes.string.isRequired,
  medium: PropTypes.string.isRequired,
};

export default UTMRedirect;
